<template>
  <div class="user">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入名称或手机号"></el-input>
      <el-select class="input" v-model="select_chain_id" placeholder="请选择学校">
        <el-option
          v-for="item in chainData"
          :key="item.shop_user_ai_chain_id"
          :label="item.chain_name"
          :value="item.shop_user_ai_chain_id"
        ></el-option>
      </el-select>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <div class="upload">
          <el-button type="primary" class="m-r" @click="userVisible = true">
            新增用户
          </el-button>
        </div>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column
          prop="name"
          label="用户名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号码"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="is_enable"
          label="是否启用"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="created"
          label="添加日期"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="chain_name"
          label="所属学校"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="320">
          <template slot-scope="scope">
            <el-button size="mini" @click="code(scope.row)">查看</el-button>
            <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" @click="handleAlter(scope.row)">{{ scope.row.is_enable === '已启用' ? '禁' : '启' }}用</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 查看弹窗 -->
     <el-dialog title="用户信息" :visible.sync="dialogCode" :before-close="close">
      <el-table :data="userMessageData">
        <el-table-column property="name" label="姓名"></el-table-column>
        <el-table-column property="gender" label="性别"></el-table-column>
        <el-table-column
          property="cell_phone"
          label="手机号码"
        ></el-table-column>
        <el-table-column
          property="enrollment"
          label="入职时间"
        ></el-table-column>
        <el-table-column property="education" label="职称"></el-table-column>
        <el-table-column property="college" label="院系"></el-table-column>
      </el-table>
     </el-dialog>

     <!-- 新增和编辑弹窗 -->
      <el-dialog
        :title="shop_user_id ? '编辑弹窗' : '新增弹窗'"
        :visible.sync="userVisible"
        :before-close="handleClose"
      >
        <el-form :model="userForm" :rules="userRules" ref="userForm" label-width="100px" class="demo-ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="userForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="昵称" prop="user_name">
                <el-input v-model="userForm.user_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年龄" prop="age">
                <el-input v-model="userForm.age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作年龄" prop="job_age">
                <el-input v-model="userForm.job_age"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="!shop_user_id">
              <el-form-item label="密码" prop="password">
                <el-input v-model="userForm.password"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别" prop="gender">
                <el-input v-model="userForm.gender"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="院系" prop="college">
                <el-input v-model="userForm.college"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="userForm.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职称" prop="education">
                <el-input v-model="userForm.education"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入职时间" prop="enrollment">
                <el-input v-model="userForm.enrollment"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="submitForm('userForm')">保存</el-button>
            <el-button @click="resetForm('userForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

     <!-- 分页 -->
      <MyPagination :total="total" :pageSize="query_info.limit" :currentPage="query_info.page" @changePage="changePage" />
  </div>
</template>

<script>
import { aiChainlist, aiUserlist, aiUserMessage, shopStatus, delUser, updateStudent, getAdminUserMessage } from '@/request/api';
import MyPagination from "@/components/MyPagination.vue";

export default {
  inject: ["reload"],
  components: {
    MyPagination
  },
  data() {
    return {
      input: '',
      select_chain_id: '',
      chainData: [],  // 机构列表
      query_info: {
        limit: 10,
        page: 1
      },
      total: 0,
      tableData: [],
      loading: false,
      userMessageData: [],
      dialogCode: false,  // 控制查看弹窗
      userVisible: false,  // 控制编辑弹窗
      shop_user_id: null,
      userForm: {},  // 编辑表单
      userRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        user_name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        job_age: [{ required: true, message: "请输入工作年龄", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        gender: [{ required: true, message: "请输入性别", trigger: "blur" }],
        college: [{ required: true, message: "请输入院系", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        enrollment: [{ required: true, message: "请选择入职时间", trigger: "blur" }],
        education: [{ required: true, message: "请输入职称", trigger: "blur" }],
      }
    }
  },
  methods: {
    // 获取学校列表
    async getChain() {
      try {
        const res = await aiChainlist(9999, 1, '');
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.chainData = res.data.data.chainList
      } catch(error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 搜索按钮
    searchInp() {
      this.query_info.page = 1
      this.tableData = []
      this.getTeacherList()
    },
    // 获取教师列表
    async getTeacherList() {
      if (this.loading) return

      this.loading = true
      try {
        const res = await aiUserlist(
          this.query_info.limit,
          this.query_info.page,
          this.input,
          this.select_chain_id,
          3
        );
        this.loading = false
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }
        res.data.data.userList.forEach((element, index, arr) => {
          element.is_enable === 0 ? (arr[index].is_enable = '已启用') : (arr[index].is_enable = '已禁用')
        })

        this.tableData = res.data.data.userList
        this.total = res.data.data.totalNumber
      } catch(error) {
        this.loading = false
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 启用或禁用
    async handleAlter(row) {
      try {
        const res = await shopStatus({
          shop_user_id: row.shop_user_id,
          is_enable: row.is_enable === '已启用' ? 1 : 0
        })
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.getTeacherList()
        this.$message({ type: "success", message: "修改成功！" });
        this.searchInp()
      } catch(error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否删除该用户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delUser({ shop_user_id: row.shop_user_id })
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.$message({ type: "success", message: "删除成功！" });
        this.reload();
      }).catch(() => {
        this.$message({ type: "info", message: "已取消删除" });
      })
    },
    // 查看
    async code(row) {
      try {
        const res = await aiUserMessage(row.shop_user_id)
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.userMessageData.push(res.data.data)
        this.dialogCode = true
      } catch(error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 关闭查看弹窗
    close() {
      this.userMessageData = []
      this.dialogCode = false
    },
    // 取分页页码
    changePage(page) {
      this.query_info.page = page
      this.tableData = []
      this.getTeacherList()
    },
    // 关闭新增和编辑弹窗
    handleClose() {
      this.shop_user_id = null
      this.userVisible = false
      this.userForm = {}
    },
    // 编辑
    async handleEdit(row) {
      const res = await getAdminUserMessage(row.shop_user_id)
      if (res.data.code === '1000') {
        this.userForm = { ...res.data.data }
        this.userVisible = true
        this.shop_user_id = row.shop_user_id
      }
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const body = {
            ...this.userForm,
            isNew: true,
            ai_jurisdiction: 3
          }
          if (this.shop_user_id) {
            body.shop_user_id = this.shop_user_id
          }
          const res = await updateStudent(body)
          if (res.data.code === '1000') {
            this.$message({
              type: "success",
              message: "新增成功",
            });
            this.searchInp()
            if (this.shop_user_id) {
              this.userVisible = false
            }
          }
        } else {
          return false
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted() {
    this.getChain();
    this.getTeacherList()
  }
}
</script>

<style lang="less" scoped>
.user {
  padding: 20px;
  .header {
    display: flex;
    margin-bottom: 20px;
    .input {
      width: 240px;
      margin-right: 10px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .upload {
        display: flex;
        .m-r {
          margin-left: 10px;
          .goto {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>